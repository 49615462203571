//React
import React from 'react'

//Actions

//Packages
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import parse from 'url-parse'

//Components
import NavMinimal from './nav/navMinimal'
import NavNone from './nav/navNone'
import FooterMinimal from './nav/footerMinimal'

//Assets
import favicon from '../images/piconext-icon.png'
import './../scss/main.scss'

//Redux

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageContext: '',
    }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  }

  // componentDidMount() {
  //   const url = parse(window.location.href, true)

  //   console.log('window.location')
  //   console.log(window.location)
  //   console.log('((((url))))')
  //   console.log(url)
  //   if (url.query && url.query.c) {
  //     this.props.updateCampaign(url.query.c)
  //   }
  // }

  render() {
    const { children } = this.props

    return (
      <div class="min-vh-100 d-flex flex-column" style={{ overflow: 'hidden' }}>
        <NavMinimal />
        {children}
        <FooterMinimal />
      </div>
    )
  }
}

export default IndexPage
// export default connect(null, mapDispatchToProps)(IndexPage)
